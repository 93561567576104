.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 600px;
}

.button_container{
    width: 100%;
display: flex;
/* flex-direction: column; */
justify-content: end;
align-items: center;    
padding-top: 20px;
}

.tab_container{
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
    margin-bottom: 20px;
}

.searchInput{
width: 100%;
}