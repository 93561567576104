.bucket_name_input:disabled{
  color:rgb(78, 78, 78);
    /* background-color: rgb(113, 108, 108);  */
}

.bucket_name_input{
    width:250px;
}
.bucket_value_input{

    width:80px;
}

.bucket_deal_delete_button_container{
 display: flex;
 justify-content: end;
 width: 100%;
 margin-top: 40px;
}
