.container{
 display: flex;
 flex-direction: column;
 justify-content: baseline;
 min-width: 880px;
}

.segment_box{
    margin-bottom: 50px;
}
.segment_item{
    padding: 10px;

}

.table_container{
 
    width:100%;
    min-height: 380px;
    position: relative;

}

.table_column{
    /* max-width: 160px;
    min-width: 160px; */
}

.table_business_column{
    max-width: 220px;
    min-width: 220px;
}

.business_details{
    display: flex;
    flex-direction: column;
}
.table_column_buttons{
    max-width: 140px;
}
.table_flag_column{
    max-width: 70px;
    min-width: 70px;
}
.tables_buttons_container{
    display: flex;
    flex-direction: column;
}
.tables_buttons_container_empty{
  display: none;
}

.searchInput{
width: 100%;
height: 40px;
flex: 1;
}

.table_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-flow: row-reverse;
}