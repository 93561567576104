.container{
 display: flex;
 flex-direction: column;
 min-width: 700px;
 overflow: scroll;
}

/* Responsive adjustment for smaller screens */
@media (max-width: 600px) {
    .container {
        width: 100%; /* Make sure it doesn't exceed the screen width */
    }
  }

.segment_box{
    margin-bottom: 50px;
}
.segment_item{
    padding: 10px;

}

.table_container{
    /* width: min-content;
    min-width: 600px; */
    min-height: 200px;
    position: relative;
    

}

.table_column{
    max-width: 120px;
    /* min-width: 120px; */
}

.table_business_column{
   
}

.business_details{
    display: flex;
    flex-direction: column;
}

.tables_buttons_container{
    display: flex;
    flex-direction: column;
}
.tables_buttons_container_empty{
  display: none;
}

.searchInput{
width: 100%;
height: 40px;
flex: 1;
margin-right: 30px;
}

.table_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-flow: row-reverse;
}