.container{
 position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
}

.loader_box{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}