.bucket_name_input:disabled{
    color:rgb(78, 78, 78);
      /* background-color: rgb(113, 108, 108);  */
  }
  
  .bucket_name_input{
      width:250px;
  }
  .bucket_value_input{
  
      width:80px;
  }
  

  .current_email{
    color: rgb(124, 124, 124);
    font-style: italic;
  }

  .email_used{
    color: red;
  }

  .email_editor_container{
    height: 310px;
  }