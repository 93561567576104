.row {
    display: flex;
    /* align-items: center;
  
    justify-content: center; */

    position: "fixed";
    top:0;
    width: 100%;
  }
  
  .left_container{
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex: 1;
     
  }

  .right_container{
    display: flex;
     min-width: 120px;
     
    
  }
  .active_link  {
    /* border-bottom: 1px solid rgba(219, 213, 213, 0.919); */
    color:rgba(91, 76, 252, 0.857) !important;
    font-weight: 500;
  }
.non_active_link{
  color:rgba(54, 52, 52, 0.797);
  font-weight: 500;
  /* font-size: 14px; */
}

  .company_icon{
    margin-right: 20px;
  }

  .nav_button{
    /* text-align: left; */
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    
  }
    .nav_button:hover{
      color:rgba(91, 76, 252, 0.689);

  }  
  
  .nav_button:focus{
   color:rgb(91 76 252);

  }

  .nav_logout_button{
    /* color:rgb(91, 76, 252); */
    font-weight: 500;
    width: 200px;
    background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.1s linear;
    
  }
  .nav_logout_button:hover{
    background: linear-gradient(90deg, #6374e7 0%, #a047e4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}  

.account_details_menu{
  min-width: 200px;
  border-radius: 8px;
  background-color: rgb(26, 22, 28);
  color: white;
}

.user_details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.user_details img{

  padding-bottom: 20px;
}


.user_details_name{
  font-size: 18px;
}
.user_details_email{
  font-size: smaller;
  margin-bottom: 30px;
}
.user_details_role{
  font-size: smaller;
  border: 1px solid rgba(255, 255, 255, 0.598);
  padding: 4px 8px;
  opacity: 0.9;
  border-radius: 4px;
  margin-bottom: 6px;

  
}
.user_details_partner_name{
  font-size: smaller;
  font-style: italic;
  background: linear-gradient(90deg, #959fe4 0%, #ca93f5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.1s linear;
  margin-bottom: 3px;
}
.user_details_icon{
  /* font-size: smaller; */
  width: auto;

}

  .nav_group_container{
    min-width: 170px;
  }
  /* .nav_group_button{
    color:rgba(125, 47, 141, 0.797);
  } */



  