.bucket_name_input:disabled{
  color:rgb(78, 78, 78);
    /* background-color: rgb(113, 108, 108);  */
}

.bucket_name_input{
    width:220px;
}
.bucket_value_input{
    width:60px;
}

.custom_label{
  width:86px;
  display: inline-block;
}


.container{
  min-height: 300px ;
}

.bucketContainer{
  min-height: 300px ;
}
