
.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  width: 100vw;
  height: 100vh;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.welcomeSection {
  max-width: 600px;
  margin: 20px 0;
}

.welcomeMessage {
  font-size: 2.5em;
  margin-bottom: 10px;
  text-transform: capitalize; 

}

.subMessage {
  font-size: 1.2em;
  color: #555;
}

.footerText {
  margin-top: 20px;
}