.container{
 display: flex;
 flex-direction: column;
 justify-content: baseline;
 /* min-width: 80vw;
 max-width: 80vw; */
 overflow-x: scroll;
}

.segment_box{
    margin-bottom: 50px;

}
.segment_item{
    padding: 10px;

}

.table_container{
    background-color: white;
    /* width: min-content; */
    width: 1100px;
    min-height: 300px;
    /* width: 80vw; */
    position: relative;

}

/* .table_column{
    max-width: 140px;
    min-width: 105px;
}
.table_business_name_column{
    max-width: 180px;
    min-width: 155px;
}
.table_column_buttons{
    max-width: 140px;
}
.table_flag_column{
    max-width: 70px;
    min-width: 70px;
} */
.tables_buttons_container{
    display: flex;
    flex-direction: column;
}
.tables_action_buttons_container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.tables_buttons_container_empty{
  display: none;
}

.identifiers_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.internal_id_text{
    font-size: 4px;
    opacity: 0.5;
    letter-spacing: 1.4px;
}


.table_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-flow: row-reverse;
}
.table_column_email{
max-width: 190px;
}