.form_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer_buttons_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option_input{
    display: flex;
}