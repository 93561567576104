
.list_container{
    margin:0 40px;
    background-color: #fff;
    min-width: 400px;
    padding: 4%;
    border-radius: 5px;

}

.button_container{
    margin-top: 10px;
}
.list_item_container{
    cursor: pointer;

}
.list_item_container:hover{
      background-color: rgba(220, 220, 220, 0.051);
}
