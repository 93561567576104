.list_container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.list{
    margin-top: 30px;
   width: 400px;
   background: rgba(255, 255, 255, 0.349);
   padding: 20px;


}