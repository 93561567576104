.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50vw;
   }
   
   .segment_box{
       margin-bottom: 50px;
   }
   .segment_item{
       padding: 10px;
   
   }
   
   .button_container{
       width: 100%;
       display: flex;
       justify-content: right;
       padding: 40px;
       padding-top: 0px;
   }
   
   .table_container{
       /* width: min-content;
       min-width: 600px; */
       width: 100%;
   }
   
   .searchInput{
   width: 100%;
   }


   .partner_column{
    min-width: 70px;
    max-width: 120px;
   }

   .partner_column{
    min-width: 70px;
    max-width: 120px;
   }
   .pricing_column{
    min-width: 90px;
   }


   .used_on_text{
    opacity: 0.5;
   }