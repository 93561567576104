
   
   .segment_box{
       margin-bottom: 20px;
       display: block;
       max-width: 100%;
       overflow-x: scroll;
   
   }
   .segment_item{
       padding: 10px;
   
   }
   

   
   .identifiers_container{
       display: flex;
       flex-direction: column;
       justify-content: center;
   }
   .internal_id_text{
       font-size: 4px;
       opacity: 0.5;
       letter-spacing: 1.4px;
   }
   .searchInput{
    margin-top: 10px;
   width: 100%;
   height: 40px;
   flex: 1;
   }
   

   
   .table_header{
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
       flex-flow: row-reverse;
   }