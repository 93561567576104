.bucket_name_input:disabled{
    color:rgb(78, 78, 78);
      /* background-color: rgb(113, 108, 108);  */
  }
  
  .bucket_name_input{
      width:220px;
  }
  .bucket_value_input{
  
      width:60px;
  }
  
  .custom_label{
    width:86px;
    display: inline-block;
}

.report_cards_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.report_card{
    min-width: 100px;
    text-wrap: wrap;
    padding: 6px;
}
.report_card span{
    margin-top: 10px;
    display: inline-block;
    width: 90px;
    text-wrap: wrap;
    line-height: 20px;
    font-weight: 500;
}
.report_description{
    text-align: center;
    opacity: 0.9;
    /* margin-top: 10px;
    display: inline-block;
    width: 90px;
    text-wrap: wrap;
    line-height: 20px;
    font-weight: 500; */
}
.report_warning{
    margin: 5px;
    text-align: center;
    opacity: 0.5;
    color: red;
    /* margin-top: 10px;
    display: inline-block;
    width: 90px;
    text-wrap: wrap;
    line-height: 20px;
    font-weight: 500; */
}

.results_container{
    display: flex;
    justify-content: center;
    align-items: center;
}