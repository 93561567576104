.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50vw;
 }
 
 .segment_box{
     margin-bottom: 50px;
 }
 .segment_item{
     padding: 10px;
 
 }
 
 .button_container{
     width: 100%;
     display: flex;
     justify-content: right;
     padding: 40px;
     padding-top: 0px;
 }
 
 .list{
  background-color: white;
 padding: 40px;
 }
 
 .searchInput{
 width: 100%;
 }
 
 .nav_container{
     width: 100%;
     display: flex;
     justify-content: end;
     margin-bottom: 100px;
 }