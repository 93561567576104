.list{
    background-color: white;
   padding:20px  40px;
   }

   .list_item{
    min-width: 200px;
   }

      
   .segment_box{
    margin-bottom: 20px;
    display: block;
    max-width: 100%;
    overflow-x: scroll;

}
.segment_item{
    padding: 10px;

}

