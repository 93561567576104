@import '~antd/dist/antd.css';

/* *{
  font-family: 'Source Sans 3', sans-serif;
} */

body{
  background: #f0f2f5;
}

.layout{
  min-height: 100vh;

}

.ant-layout-content{
}

.header{
  background: #f0f2f5;
  
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.03), 0 1px 6px -1px rgba(0,0,0,.02), 0 2px 4px 0 rgba(0,0,0,.02);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  padding-right: 18px;
  padding-left: 18px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 50px 0;
  padding-top: 100px;
  /* background-color: #f0f2f56e; */
  width: 100vw;
  /* height: 100%; */
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.centering-container{
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-layout-content{
  min-height: 100vh;
}

/* .ant-btn-default {
border-radius: 60px;
border-color:  #9e20ff8f ;
max-width: 200px;
color:#9e20ff8f ;
background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  
} */
.ant-btn-default{
  background-color:transparent; /* Button background color */
  border: 1px solid rgb(91 76 252); /* Border width and make it transparent */
  border-radius: 18px; /* Rounded corners for the button */
  color:rgb(91 76 252);
  background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
transition: all 0.1s linear;
}





.ant-btn-default:hover,.ant-btn-default:focus {
  background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
  color:white !important;
  /* opacity: 0.8; */
  border-color: rgb(226, 200, 236) !important;
-webkit-background-clip:border-box;
-webkit-text-fill-color: white;

  
}


.ant-btn-primary{
  border-radius: 60px;
  background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
  /* width: 100%; */
  border-color: rgb(226, 200, 236) !important;
  max-width: 200px;
  box-shadow: 0px 6px 17px 0px rgba(69, 86, 252, 0.25);
  color: white;
  
}

 .ant-btn-primary:hover{
  background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
  color:white !important;
  opacity: 0.8;
  border-color: rgb(226, 200, 236) !important;
}

.ant-btn-primary:focus{
  background: linear-gradient(90deg, #3F59FB 0%, #9F20FF 100%);
  color:white !important;
  opacity: 0.8;
  border-color: rgb(226, 200, 236) !important;
}






.ant-btn-link{
}


.site-page-header{
  margin-bottom: 20px;
}

